import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

const Editor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange }, ref) => {
  const containerRef = useRef(null)
  const quillRef = useRef(null)
  const defaultValueRef = useRef(defaultValue)
  const onTextChangeRef = useRef(onTextChange)
  const onSelectionChangeRef = useRef(onSelectionChange)

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange
    onSelectionChangeRef.current = onSelectionChange
  })

  useEffect(() => {
    const container = containerRef.current
    const editorContainer = container.appendChild(document.createElement('div'))
    const quill = new Quill(editorContainer, {
      theme: 'snow'
    })

    ref.current = quill
    quillRef.current = quill

    quill.on(Quill.events.TEXT_CHANGE, () => {
      const textContent = quill.root.innerHTML
      onTextChangeRef.current?.(textContent)
    })

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args)
    })

    return () => {
      ref.current = null
      quillRef.current = null
      container.innerHTML = ''
    }
  }, [ref])

  useEffect(() => {
    if (quillRef.current) {
      if (defaultValueRef.current) {
        if (typeof defaultValueRef.current === 'string') {
          quillRef.current.root.innerHTML = defaultValueRef.current
        } else {
          quillRef.current.setContents(defaultValueRef.current)
        }
      }
    }
  }, [defaultValueRef])

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.enable(!readOnly)
    }
  }, [readOnly])

  return <div ref={containerRef}></div>
})

Editor.displayName = 'Editor'

export default Editor
