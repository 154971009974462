import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TicketDetails from '../components/ticket/view/TicketDetails'
import { getTicket } from '../services/ticketService'
import { useLocation } from 'react-router-dom'

function TicketDetailsContainer() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  // eslint-disable-next-line
  const [isRegistered, setIsRegistered] = useState(true)
  const [ticketData, setTicketData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const { ticketId } = useParams()

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const ticket = await getTicket(ticketId)
        setTicketData(ticket)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching ticket:', error)
        setError(error.message || 'An error occurred while fetching ticket details.')
        setLoading(false)
      }
    }

    fetchTicket()
    // eslint-disable-next-line
  }, [user?.userId])

  return (
    <TicketDetails user={user} isRegistered={isRegistered} ticketData={ticketData} error={error} loading={loading} />
  )
}

export default TicketDetailsContainer
