import React, { useRef, useEffect, useState } from 'react'
import config from '../../../config/config'

const AddressAutocomplete = ({ value = '', onPlaceSelected }) => {
  const inputRef = useRef(null)
  const MAP_API_KEY = config.GOOGLE_MAPS_API_KEY
  const [loadingError, setLoadingError] = useState(false)

  useEffect(() => {
    let autocomplete
    const MAX_RETRIES = 3
    let retries = 0

    const initializeAutocomplete = () => {
      if (inputRef.current && window.google?.maps?.places) {
        try {
          autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            types: ['geocode']
          })
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            onPlaceSelected?.(place)
            inputRef.current.value = place.name || ''
          })
          setLoadingError(false)
        } catch (error) {
          console.error('Error initializing autocomplete:', error)
          setLoadingError(true)
        }
      }
    }

    const loadGoogleMapsAPI = () => {
      return new Promise((resolve, reject) => {
        if (window.google?.maps?.places) {
          initializeAutocomplete()
          resolve()
        } else {
          const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`)
          if (existingScript) existingScript.remove()

          const script = document.createElement('script')
          script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`
          script.async = true
          script.defer = true

          script.onload = () => {
            initializeAutocomplete()
            resolve()
          }

          script.onerror = () => {
            retries += 1
            console.warn(`Retrying Google Maps API load... Attempt ${retries}`)
            if (retries <= MAX_RETRIES) {
              setTimeout(loadGoogleMapsAPI, 1000)
            } else {
              reject(new Error('Failed to load Google Maps API after multiple attempts.'))
              setLoadingError(true)
            }
          }

          document.head.appendChild(script)
        }
      })
    }

    loadGoogleMapsAPI().catch((error) => {
      console.error(error)
      setLoadingError(true)
    })

    return () => {
      if (autocomplete) {
        autocomplete.unbindAll()
        autocomplete = null
      }
      const script = document.querySelector(`script[src*="maps.googleapis.com"]`)
      if (script) script.remove()
    }
  }, [MAP_API_KEY, onPlaceSelected])

  return (
    <div>
      <input ref={inputRef} className="form-control" type="text" defaultValue={value} id="venueName" name="venueName" />
      {loadingError && <p className="text-danger">Failed to load location suggestions. Please try again later.</p>}
    </div>
  )
}

export default AddressAutocomplete
