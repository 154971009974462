import axios from 'axios'
import Cookies from 'js-cookie'
import config from '../config/config'

const baseURL = config.BASE_API_URL

const api = axios.create({
  baseURL
})

api.interceptors.request.use(
  (config) => {
    const token = Cookies.get('gn_userToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log('error returning', error)
    try {
      if (error.response.status === 401 && error.response.data.message === 'Invalid or expired token') {
        Cookies.remove('gn_userToken')
        localStorage.removeItem('user')
        window.location.replace('/')
      }
    } catch (error) {
      console.error('Error handling token expiration:', error)
    }
    return Promise.reject(error)
  }
)

export default api
