import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ForgotPassword from '../components/forgot-password/ForgotPassword'
import { forgotPassword } from '../services/userService'

function ForgotPasswordContainer() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [formData, setFormData] = useState({
    email: ''
  })
  const [errors, setErrors] = useState({
    email: ''
  })
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail')
    if (storedEmail) {
      setFormData((prevState) => ({ ...prevState, email: storedEmail }))
    }

    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target
    let newErrors = { ...errors }

    switch (name) {
      case 'email':
        newErrors.email = value === '' ? 'Email is required' : ''
        break
      default:
        break
    }

    setErrors(newErrors)
  }

  const handleSubmit = async (formData) => {
    const { email } = formData
    setLoading(true)
    localStorage.setItem('rememberedEmail', email)

    try {
      const result = await forgotPassword(email)
      if (result) setMessage(result.message)
      setShowToast(true)
      setLoading(false)

      if (result.success) {
        const timeout = setTimeout(() => {
          navigate('/reset-password')
        }, 2000)

        return () => clearTimeout(timeout)
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`)
      setShowToast(true)
      setLoading(false)
    }
  }

  return (
    <ForgotPassword
      onSubmit={handleSubmit}
      loading={loading}
      formData={formData}
      handleChange={handleChange}
      handleInputBlur={handleInputBlur}
      errors={errors}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
    />
  )
}

export default ForgotPasswordContainer
