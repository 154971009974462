const Ad5Component = ({ index }) => {
  const adIndex = Math.floor(index / 5) % 5

  return (
    <div id={`ad-${index}-${adIndex}`} className="ad-container row">
      <iframe
        title={`ad-${index}-${adIndex}`}
        width="1080"
        height="1080"
        src="https://g.adspeed.net/ad.php?do=html&aid=1122374&oid=29387&wd=1080&ht=1080&target=_blank"
        frameBorder="0"
        scrolling="no"
        allowtransparency="true"
        hspace="0"
        vspace="0"
        style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }}
      ></iframe>
    </div>
  )
}

export default Ad5Component
