import React, { useEffect } from 'react'
import './TicketScan.css'
import Toasts from '../../common/toasts/Toasts'
import { qrScanDataForRsvp } from '../../../services/eventService'

function TicketScan({ user, rsvpId, influencerId }) {
  console.log('TicketScan:', { user, rsvpId, influencerId })
  const [showToast, setShowToast] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState('')

  useEffect(() => {
    const submitQRScanData = async () => {
      try {
        const response = await qrScanDataForRsvp(rsvpId, influencerId, user.userId)
        console.log('QR Scan Data:', response)
        setShowToast(true)
        setToastMessage(response.data.message)
      } catch (error) {
        console.error('Error scanning QR code:', error)
        setShowToast(true)
        setToastMessage('Failed to submit QR code data.')
      }
    }

    if (!user) {
      setShowToast(true)
      setToastMessage('You must sign in to GreatNight in order to scan this code.')
    } else {
      submitQRScanData()
    }
  }, [influencerId, rsvpId, user])

  return (
    <div id="ticket-scan" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div className="row mt-5 mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <img src="/assets/images/logo.svg" alt="GreatNight Logo" id="logo" />
              </div>
              {!user && (
                <div id="success-msg">
                  <h2 className="mt-5 mb-5 text-center epilogue-400">Unauthorized</h2>
                  <h3 className="mb-3 text-center epilogue-400">
                    You must sign in to GreatNight in order to scan this code.{' '}
                    <a href="/">Click this link to Sign in.</a>
                  </h3>
                </div>
              )}
            </div>
          </header>

          {user && (
            <main className="ps-5 pe-5">
              <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
              <h2 className="mt-5 mb-5 inter-700 text-center">Event QR Scan Details</h2>
              <p className="mt-3 mb-3 inter-400">Rsvp ID: {rsvpId}</p>
              <p className="mt-4 mb-3 inter-400">Influencer ID: {influencerId}</p>
            </main>
          )}
        </div>
      </div>
    </div>
  )
}

export default TicketScan
