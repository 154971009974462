import { useEffect, useState } from 'react'
import { Image, IText, filters, Circle, Rect } from 'fabric'
import { HexColorPicker } from 'react-colorful'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faImage, faSquare } from '@fortawesome/free-regular-svg-icons'
import { faDownload, faFilter, faFont, faPencil, faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons'

const Toolbox = ({ canvas, currentFilter, setCurrentFilter, imageURL }) => {
  const [drawingMode, setDrawingMode] = useState(false)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [color, setColor] = useState('red')

  useEffect(() => {
    if (!canvas || !canvas.getActiveObject() || !canvas.getActiveObject().isType('image')) return

    function getSelectedFilter() {
      switch (currentFilter) {
        case 'sepia':
          return new filters.Sepia()
        case 'vintage':
          return new filters.Vintage()
        case 'invert':
          return new filters.Invert()
        case 'polaroid':
          return new filters.Polaroid()
        case 'grayscale':
          return new filters.Grayscale()
        default:
          return null
      }
    }

    const filter = getSelectedFilter()
    const img = canvas.getActiveObject()

    img.filters = filter ? [filter] : []
    img.applyFilters()
    canvas.renderAll()
  }, [currentFilter, canvas])

  useEffect(() => {
    if (!canvas) return

    async function loadFlyerImage(url) {
      const image = await Image.fromURL(url, { crossOrigin: 'anonymous' })
      image.scale(0.5)
      // canvas.getObjects().forEach((obj) => {
      //   if (obj.isType('image')) {
      //     canvas.remove(obj)
      //   }
      // })
      canvas.add(image)
      canvas.centerObject(image)
      canvas.setActiveObject(image)
    }

    loadFlyerImage(imageURL)
  }, [canvas, imageURL])

  function fileHandler(e) {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = async (e) => {
      const image = await Image.fromURL(e.target.result)
      image.scale(0.5)
      canvas.add(image)
      canvas.centerObject(image)
      canvas.setActiveObject(image)
    }
    reader.readAsDataURL(file)
    e.target.value = ''
  }

  function addText() {
    const text = new IText('Edit this text')
    canvas.add(text)
    text.set({ fontWeight: 'bold' })
    canvas.centerObject(text)
    canvas.setActiveObject(text)
  }

  function handleColorChange(color) {
    setColor(color)
    const activeObject = canvas.getActiveObject()

    if (activeObject && activeObject.isType('i-text')) {
      activeObject.set({ fill: color })
      canvas.renderAll()
    }

    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.color = color
    }

    if (activeObject && activeObject.isType('circle')) {
      activeObject.set({ fill: color })
      canvas.renderAll()
    }

    if (activeObject && activeObject.isType('rect')) {
      activeObject.set({ fill: color })
      canvas.renderAll()
    }
  }

  function addCircle() {
    const circle = new Circle({
      radius: 35,
      fill: color,
      stroke: 'white',
      strokeWidth: 0.7,
      left: 100,
      top: 100
    })
    canvas.add(circle)
    canvas.centerObject(circle)
    canvas.setActiveObject(circle)
  }

  function addSquare() {
    const square = new Rect({
      width: 90,
      height: 30,
      fill: color,
      stroke: 'white',
      strokeWidth: 0.7
    })
    canvas.add(square)
    canvas.centerObject(square)
    canvas.setActiveObject(square)
  }

  function toggleDrawingMode() {
    canvas.isDrawingMode = !canvas.isDrawingMode
    canvas.freeDrawingBrush.color = color
    canvas.freeDrawingBrush.width = 7
    setDrawingMode(canvas.isDrawingMode)
  }

  function downloadImage() {
    const link = document.createElement('a')
    link.download = 'photo_editor_image.png'
    link.href = canvas.toDataURL()
    link.click()
  }

  function removeObject() {
    const activeObject = canvas.getActiveObject()
    if (activeObject) {
      canvas.remove(activeObject)
    }
  }

  function clearAll() {
    if (window.confirm('Are you sure you want to clear all?')) {
      canvas.remove(...canvas.getObjects())
    }
  }

  return (
    <div className="toolbox">
      <button title="Add image">
        <FontAwesomeIcon icon={faImage} />
        <input type="file" accept=".png, .jpg, .jpeg" onChange={fileHandler} />
      </button>
      <button title="Add text" onClick={addText}>
        <FontAwesomeIcon icon={faFont} />
      </button>
      <div
        style={{ width: '32px', height: '32px', backgroundColor: color, border: '2px solid red' }}
        onClick={() => setShowColorPicker(!showColorPicker)}
      >
        {showColorPicker && <HexColorPicker color={color} onChange={(e) => handleColorChange(e)} className="mt-5" />}
      </div>
      <button title="Add circle" onClick={addCircle}>
        <FontAwesomeIcon icon={faCircle} />
      </button>
      <button title="Add circle" onClick={addSquare}>
        <FontAwesomeIcon icon={faSquare} />
      </button>
      <button title="Drawing mode" onClick={toggleDrawingMode} className={drawingMode ? 'active' : ''}>
        <FontAwesomeIcon icon={faPencil} />
      </button>
      <button
        title="Filters"
        onClick={() => setCurrentFilter(currentFilter ? null : 'sepia')}
        className={currentFilter ? 'active' : ''}
      >
        <FontAwesomeIcon icon={faFilter} />
      </button>
      {currentFilter && (
        <select onChange={(e) => setCurrentFilter(e.target.value)} value={currentFilter}>
          <option value="sepia">Sepia</option>
          <option value="vintage">Vintage</option>
          <option value="invert">Invert</option>
          <option value="polaroid">Polaroid</option>
          <option value="grayscale">Grayscale</option>
        </select>
      )}
      <button title="Remove object" onClick={removeObject}>
        <FontAwesomeIcon icon={faTrash} />
      </button>
      <button title="Clear all" onClick={clearAll}>
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
      <button title="Download as image" onClick={downloadImage}>
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </div>
  )
}

export default Toolbox
