import { useRef, useEffect, useState } from 'react'
import { Canvas, PencilBrush } from 'fabric'
import Toolbox from './Toolbox'
import EditorCanvas from './EditorCanvas'
import './FabricEditor.css'

function FabricEditor({ imageURL, handleFileChange }) {
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState(null)
  const [currentFilter, setCurrentFilter] = useState(null)

  useEffect(() => {
    const canvas = new Canvas(canvasRef.current, { backgroundColor: 'white' })
    const canvasbox = document.querySelector('.canvasbox')
    const { width, height } = canvasbox.getBoundingClientRect()
    canvas.setDimensions({ width, height: height * 0.98 })
    const brush = new PencilBrush(canvas)
    brush.color = 'black'
    brush.width = 4
    canvas.freeDrawingBrush = brush
    setCanvas(canvas)

    return () => canvas.dispose()
  }, [canvasRef, setCanvas])

  const base64ToImageFile = (base64Data, fileName) => {
    const byteString = atob(base64Data.split(',')[1])
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([uint8Array], { type: 'image/png' })
    const file = new File([blob], fileName, { type: 'image/png' })

    return file
  }

  const handleUploadFlyer = () => {
    const dataURL = canvas.toDataURL({ format: 'png', quality: 0.8 })
    const imageFile = base64ToImageFile(dataURL, 'flyer.png')
    handleFileChange(imageFile)
  }

  return (
    <>
      <div className="editor">
        <Toolbox
          canvas={canvas}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          imageURL={imageURL}
        />
        <EditorCanvas ref={canvasRef} canvas={canvas} setCurrentFilter={setCurrentFilter} />
      </div>
      <button
        type="button"
        className="btn"
        id="flyer-upload-button"
        style={{ marginTop: '20px' }}
        onClick={() => handleUploadFlyer()}
      >
        Use this image as flyer
      </button>
    </>
  )
}

export default FabricEditor
