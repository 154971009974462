import React from 'react'

export default function Help() {
  const style = {
    'min-width': '100%',
    'max-width': '100%',
    height: '700px',
    border: 'none'
  }

  return (
    <div>
      <iframe
        id="JotFormIFrame-243251615009146"
        title="Feedback Form"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src="https://form.jotform.com/243251615009146"
        frameborder="0"
        scrolling="yes"
        style={style}
      ></iframe>
      <script src="https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js"></script>
      <script>
        window.jotformEmbedHandler( "iframe[id='JotFormIFrame-243251615009146']", "https://form.jotform.com/" )
      </script>
    </div>
  )
}
