import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { getActivities, getFriendsOfUser } from '../../services/userService'

export default function Dashboard() {
  const [numOfEvents, setNumOfEvents] = useState(0)
  const [numOfConnectedPeople, setNumOfConnectedPeople] = useState(0)
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null
  const fullName = _userInfo ? JSON.parse(_userInfo).fullName : ''
  const [activities, setActivities] = useState([])
  const [friendsOfUser, setFriendsOfUser] = useState([])
  const userToken = Cookies.get('gn_userToken')
  const fetchUserActivities = async () => {
    if (!userInfo) return
    const data = await getActivities(userToken, userInfo.userId)
    if (data.success) {
      setActivities(data.data)
      setNumOfEvents(data.data.length)
    }
  }

  const fetchFriendsOfUser = async () => {
    if (!userInfo) return
    const data = await getFriendsOfUser(userToken, userInfo.userId)
    if (data.success) {
      setFriendsOfUser(data.data)
      setNumOfConnectedPeople(data.data.length)
    }
  }

  useEffect(() => {
    fetchUserActivities()
    fetchFriendsOfUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="max-w-4xl mx-auto py-6 sm:px-6 lg:px-8 px-3">
      <h1 className="my-4 text-2xl font-semibold text-gray-900">Dashboard</h1>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
        <div className="sm:col-span-full">
          <div className="flex">
            <img src={userInfo?.profileImgUrl} alt="Profile" className="h-36 w-36 object-cover rounded-lg" />
            <div className="mx-4">
              <h3 className="text-xl font-medium leading-6 text-gray-900">{fullName}</h3>
              <p className="mt-2 max-w-2xl text-sm text-gray-800">Current Status</p>
              <p className="mt-1 py-1 rounded-md bg-purple-700 max-w-2xl  text-white text-center text-xl">
                {userInfo?.status}
              </p>
            </div>
          </div>
        </div>
        <div className="sm:col-span-full">
          <div className="bg-[#96f468] p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-4 text-center">Congratulations!</h2>

            <div className="mt-4">
              <p className="text-3xl font-bold mb-4 text-center">
                {`You've Earned ${userInfo?.totalPoints ?? 0} Points`}
              </p>
              <p className="text-xl text-center">Only 750 Points To VIP Status</p>
            </div>
          </div>
        </div>

        <div className="sm:col-span-full">
          <h2 className="text-xl text-center font-semibold">
            {`You\`ve attended ${numOfEvents} events and connected with ${numOfConnectedPeople} People.`}
            <br /> Way To Go!
          </h2>
        </div>

        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">{`Your Activities (${activities.length})`}</h2>
            <p className="mt-2 text-sm text-gray-500 ">
              <div className="flex gap-3 overflow-x-scroll">
                {activities.map((activity, index) => (
                  <div className="rounded-lg">
                    <img src={activity.flyer} alt={activity.name} className="h-16 w-16 object-cover rounded-xl" />
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">{`Your Friends (${friendsOfUser.length})`}</h2>
            <p className="mt-2 max-w-2xl text-sm text-gray-500">
              <div className="flex gap-3 overflow-x-scroll">
                {friendsOfUser.map((friend, index) => (
                  <div className="rounded-lg">
                    <img
                      src={friend?.profile_picture_url || '/assets/images/default_pfp.png'}
                      alt={friend?.username}
                      className="h-16 w-16 object-cover rounded-full"
                    />
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
