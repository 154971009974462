import React, { useCallback, useRef, useState } from 'react'

import './EventCreate.css'
import Map from '../../common/map/Map'
import AddressAutocomplete from '../../common/address-autocomplete/AddressAutocomplete'
import Toasts from '../../common/toasts/Toasts'
import Editor from '../../common/editor/Editor'
import DOMPurify from 'dompurify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { generateTimeOptions } from '../../../utils/timeUtils'
import FlyerThumbnails from './FlyerThumbnails'
import { EVENT_CATEGORIES, EVENT_MODELS, EVENT_STATES } from '../../../config/eventData'

function EventCreate({
  formData,
  setFormData,
  selectedFile,
  selectedName,
  handleFileChange,
  handleChange,
  handleSubmit,
  handleVenueSelected,
  address,
  disableButton,
  imageLoading,
  errors,
  handleInputBlur,
  setShowToast,
  showToast,
  toastMessage,
  loading,
  generateFlyer,
  images,
  imageURL
}) {
  const quillRef = useRef(null)
  const navigate = useNavigate()
  const timeOptions = generateTimeOptions()

  const handleTextChange = useCallback(() => {
    const quill = quillRef.current
    const description = quill.root.innerHTML
    const sanitizedDescription = DOMPurify.sanitize(description)

    setFormData((prevFormData) => ({
      ...prevFormData,
      description: sanitizedDescription
    }))
  }, [setFormData])

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div id="event-create" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="mb-3 d-block text-black mt-3"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="text-right">
              <FontAwesomeIcon
                icon={faCircleQuestion}
                className="cursor-pointer hover:text-purple-600"
                onClick={() => navigate('/promoter/help')}
              />
            </div>
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <h3 className="inter-700 mt-3 mb-3">Basic Info</h3>
            <p>
              Name your event and tell event - goers, why they should come. Add details that highlight what makes it
              unique
            </p>
            <form onSubmit={handleSubmit}>
              <div className="mt-3 mb-3">
                <label htmlFor="name" className="form-label">
                  Event Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  placeholder="Enter event title"
                  onChange={handleChange}
                  required
                  onBlur={handleInputBlur}
                />
                <div className="form-text text-danger">{errors?.name}</div>
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Event Description
                </label>
                <Editor ref={quillRef} defaultValue={formData.description} onTextChange={handleTextChange} />
                {errors.description && <span className="error">{errors.description}</span>}

                <input type="hidden" name="description" value={formData.description} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="category" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                >
                  {EVENT_CATEGORIES.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <div className="form-text text-danger">{errors?.category}</div>
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  className="form-select"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                >
                  <option value="1">Public</option>
                  <option value="0">Private</option>
                </select>
                <div className="form-text text-danger">{errors?.type}</div>
              </div>
              <div className="mb-3">
                <label htmlFor="tags" className="form-label">
                  Tags
                </label>
                <p className="mt-1 mb-2">
                  Improve discoverability of your event by adding tags relevant to the subject matter.
                </p>
                <input
                  type="text"
                  className="form-control"
                  id="tags"
                  name="tags"
                  value={formData.tags}
                  placeholder="Add tags"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="form-text text-danger">{errors?.tags}</div>
                <div className="form-text mt-2">Enter tags separated by comma. e.g., fun, holiday, party</div>
              </div>
              <div className="mb-3">
                <label htmlFor="model" className="form-label">
                  Model
                </label>
                <p className="mt-1 mb-2">Who do you want to be featured in your autogenerated flyer</p>
                <div className="form-check form-group">
                  {EVENT_MODELS.map((model) => (
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id={model.id}
                        name={model.name}
                        value={model.value}
                        checked={formData.model === model.value}
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                        required
                      />
                      <label className="form-check-label" htmlFor={model.id}>
                        {model.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="form-text text-danger">{errors?.model}</div>
              </div>

              <button
                type="button"
                className="btn"
                id="flyer-button"
                style={{ marginTop: '20px' }}
                onClick={generateFlyer}
                disabled={disableButton}
              >
                Generate Flyer With AI
              </button>

              {images && images.length > 0 && <FlyerThumbnails images={images} handleFileChange={handleFileChange} />}

              <h3 className="inter-700 mb-0 mt-2">Event Image</h3>

              <div>
                <label htmlFor="" className="form-label mt-3 mb-3">
                  Select One Of The Above Images or Upload Hero Photo
                </label>

                <FileUploader selectedName={selectedName} onFileSelect={handleFileChange} imageLoading={imageLoading} />
              </div>

              {images.length === 0 && imageURL && (
                <>
                  <div className="full-image-container" style={{ marginTop: '20px' }}>
                    <img src={imageURL} alt="Full size" style={{ width: '100%', height: 'auto' }} />
                  </div>
                </>
              )}

              <div className="mt-3 mb-3">
                <label htmlFor="ticketLink" className="form-label">
                  Ticket Link
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ticketLink"
                  name="ticketLink"
                  value={formData.ticketLink}
                  placeholder="Add ticket link"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                />
              </div>
              <h3 className="inter-700">Venue</h3>
              <p className="mt-3 mb-3">
                Help people in the area discover your event and let attendees know where to show up.
              </p>
              <div className="mb-3">
                <label htmlFor="venueLocation" className="form-label d-none">
                  Venue Location
                </label>
                <h5>Venue Name</h5>
                {address && <Map address={address} />}
              </div>
              <div className="mb-3">
                <AddressAutocomplete onPlaceSelected={handleVenueSelected} value={formData.venueName} />
              </div>
              <div className="mb-3">
                <label htmlFor="street" className="form-label">
                  Street
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                  value={formData.street}
                  placeholder="Street"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="form-text text-danger">{errors?.street}</div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  />
                  <div className="form-text text-danger">{errors?.city}</div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="state" className="form-label">
                    State/Province
                  </label>
                  <select
                    className="form-select"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  >
                    {EVENT_STATES.map((state) => (
                      <option key={state.value} value={state.value}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  <div className="form-text text-danger">{errors?.state}</div>
                </div>
                <div className="col">
                  <label htmlFor="zip" className="form-label">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    placeholder="Postal Code*"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  />
                  <div className="form-text text-danger">{errors?.zip}</div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  disabled
                >
                  <option value="USA">United States</option>
                </select>
              </div>
              <h3 className="inter-700">Date and time</h3>
              <p className="mt-3 mb-3">
                Tell event-goers when your event starts and ends so they can make plans to attend.
              </p>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="startDate" className="form-label">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    aria-describedby="validationStartDateFeedback"
                    required
                  />
                  <div id="validationStartDateFeedback" className="text-danger">
                    {errors?.startDate}
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="startDate" className="form-label">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    required
                    aria-describedby="validationEndDateFeedback"
                    disabled={!formData?.startDate}
                  />
                  <div id="validationEndDateFeedback" className="text-danger">
                    {errors?.endDate}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="start" className="form-label">
                    Start Time
                  </label>
                  <select
                    className="form-control"
                    id="start"
                    name="start"
                    value={formData.start}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationStartFeedback"
                    disabled={!formData?.endDate}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.startTime}
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="end" className="form-label">
                    End Time
                  </label>
                  <select
                    className="form-control"
                    id="end"
                    name="end"
                    value={formData.end}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationEndFeedback"
                    disabled={!formData?.start}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.endTime}
                  </div>
                </div>
              </div>

              <button type="submit" className="btn mt-4" id="continue" disabled={disableButton}>
                Continue
              </button>
            </form>
          </main>
        </div>
      </div>
    </div>
  )
}

const FileUploader = ({ onFileSelect, selectedName, imageLoading }) => {
  const [dragOver, setDragOver] = useState(false)
  const [thumbnail, setThumbnail] = useState(null)

  const [dragOverCounter, setDragOverCounter] = useState(0)
  const fileInputRef = useRef(null)

  const handleDragEnter = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOverCounter((prev) => prev + 1)
  }, [])

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)

      setDragOverCounter((prev) => prev - 1)
      if (dragOverCounter <= 1) {
        setDragOverCounter(0)
      }
    },
    [dragOverCounter]
  )

  const handleDragOver = useCallback((e) => {
    setDragOver(true)
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onFileSelect(e.dataTransfer.files[0])
        generateThumbnail(e.dataTransfer.files[0])
      }
    },
    [onFileSelect]
  )

  const handleChange = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        onFileSelect(e.target.files[0])
        generateThumbnail(e.target.files[0])
      }
    },
    [onFileSelect]
  )

  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const generateThumbnail = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setThumbnail(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  return (
    <div
      className={`file-upload ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={triggerFileInputClick}
    >
      {imageLoading ? (
        <div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Uploading...</span>
            </div>
          </div>
          <div className="uploading-text">Uploading...</div>
        </div>
      ) : (
        <>
          {thumbnail ? (
            <img src={thumbnail} alt="thumbnail" className="thumbnail" />
          ) : (
            <img src="/assets/images/icons/file.svg" alt="upload" />
          )}
          <h3> {selectedName || 'Drag and drop a file or click here to select a file'}</h3>
          <p className="mt-3">JPEG or PNG, no larger than 10MB. Recommended size is 500x500.</p>
        </>
      )}

      <input type="file" ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} />
    </div>
  )
}
export default EventCreate
