import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals'

const logMetric = (metric, onPerfEntry) => {
  console.log(`Collected ${metric.name} metric:`, metric)
  onPerfEntry(metric)
}

const attachMetricsListeners = (onPerfEntry) => {
  console.log('Attaching web-vitals listeners...')
  onCLS((metric) => logMetric(metric, onPerfEntry))
  onFCP((metric) => logMetric(metric, onPerfEntry))
  onINP((metric) => logMetric(metric, onPerfEntry))
  onLCP((metric) => logMetric(metric, onPerfEntry))
  onTTFB((metric) => logMetric(metric, onPerfEntry))
}

const reportWebVitals = (onPerfEntry, options = {}) => {
  if (onPerfEntry && typeof onPerfEntry === 'function') {
    attachMetricsListeners(onPerfEntry)

    if (options.onRouteChange) {
      options.onRouteChange(() => {
        console.log('Route changed. Reattaching web-vitals listeners...')
        attachMetricsListeners(onPerfEntry)
      })
    }
  } else {
    console.warn('reportWebVitals: onPerfEntry is not a valid function.')
  }
}

export default reportWebVitals
