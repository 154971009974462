import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import './Vibes.css'
import { getVibesForUser } from '../../services/eventService'
import { Header } from '../common/header/Header'
import { showToast } from '../../services/toastService'
import { followUser, unfollowUser } from '../../services/userService'

function Vibes() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [vibes, setVibes] = useState([])
  const [filteredVibes, setFilteredVibes] = useState([])
  const [tags, setTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)
  const [thisWeekVibes, setThisWeekVibes] = useState([])
  const [nextWeekVibes, setNextWeekVibes] = useState([])
  const [futureVibes, setFutureVibes] = useState([])
  const [loading, setLoading] = useState(true)

  const dayOfWeekMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }

  useEffect(() => {
    const getVibes = async () => {
      if (user && user.userId && user.role) {
        const response = await getVibesForUser(user.userId)
        if (response.success) {
          if (response.data.vibes.length > 0) {
            setVibes(response.data.vibes)
            setFilteredVibes(response.data.vibes)
            let tagsData = []
            response.data.vibes.forEach((element) => {
              tagsData.push(element.Event?.tags.split(','))
            })
            const uniqueTags = [...new Set(tagsData.flat().map((tag) => tag.trim()))]
            setTags(uniqueTags)
            setAllTags(uniqueTags)
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
    getVibes()
    // eslint-disable-next-line
  }, [])

  const handleFollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(user.token, user.userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            event.Event.User.is_following = 1
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(user.token, user.userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            if (event.Event && event.Event.User) {
              event.Event.User.is_following = 0
            }
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const applyFilters = (tag) => {
    if (tag === null) {
      setFilteredVibes(vibes)
      setTags(allTags)
      return
    }

    let filtered = []

    if (dayOfWeekMapping[tag] !== undefined) {
      const selectedDay = tag.charAt(0).toUpperCase() + tag.slice(1)
      setSelectedDay(selectedDay)

      const now = moment()
      const startOfWeek = now.clone().startOf('week')
      const endOfWeek = now.clone().endOf('week')
      const startOfNextWeek = startOfWeek.clone().add(1, 'week')
      const endOfNextWeek = endOfWeek.clone().add(1, 'week')

      let thisWeekVibes = []
      let nextWeekVibes = []
      let futureVibes = []

      vibes.forEach((vibe) => {
        const vibeDate = moment(vibe.Event?.start)
        const vibeDay = vibeDate.format('dddd')

        if (vibeDay !== selectedDay) return

        if (vibeDate.isBetween(startOfWeek, endOfWeek, null, '[]')) {
          thisWeekVibes.push(vibe)
        } else if (vibeDate.isBetween(startOfNextWeek, endOfNextWeek, null, '[]')) {
          nextWeekVibes.push(vibe)
        } else if (vibeDate.isAfter(endOfNextWeek)) {
          futureVibes.push(vibe)
        }
      })

      setThisWeekVibes(thisWeekVibes)
      setNextWeekVibes(nextWeekVibes)
      setFutureVibes(futureVibes)

      filtered = [...thisWeekVibes, ...nextWeekVibes, ...futureVibes]
    } else {
      filtered = vibes.filter((vibe) =>
        vibe.Event?.tags
          .split(',')
          .map((t) => t.trim())
          .includes(tag)
      )
    }

    setFilteredVibes(filtered)

    const filteredTags = [...new Set(filtered.flatMap((vibe) => vibe.Event?.tags.split(',').map((t) => t.trim())))]
    setTags(filteredTags.length > 0 ? filteredTags : allTags)
  }

  const filterVibesByTag = (tag) => {
    if (tag === null) {
      setThisWeekVibes([])
      setNextWeekVibes([])
      setFutureVibes([])
    }
    setSelectedTag(tag)
    applyFilters(tag)
  }

  const RenderVibeDetails = (vibe) => {
    return (
      <div key={vibe.event_id} className="row">
        <div className="col">
          <div className="card event border-0">
            {vibe.Event?.flyer && (
              <Link to={`/promoter/event/${vibe.Event?.slug}`} className="text-decoration-none">
                <img src={vibe.Event?.flyer} className="card-img-top rounded-3" alt="Event" />
              </Link>
            )}
            <div className="card-body ps-0 pe-0">
              <div className="row">
                <div className="col-auto">
                  <img src="/assets/images/icons/user.png" alt="" className="d-inline-block place-marker" />
                  <div className="d-inline-block ms-2">
                    <Link to={`/promoter/event/${vibe.Event?.slug}`} className="text-decoration-none">
                      <h5 className="card-title epilogue-600 text-truncate" style={{ maxWidth: '300px' }}>
                        {vibe.Event?.name}
                      </h5>
                    </Link>
                    <p className="inter-400 mb-0 location-address">
                      <span className="location-name mb-0">
                        {vibe.Event?.venue_name ? `${vibe.Event?.venue_name}, ` : ''} {vibe.Event?.city}, posted by{' '}
                        <strong>{vibe.Event?.User?.name}</strong>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col text-end">
                  {user && user.userId && user.userId !== vibe.Event?.user_id && (
                    <div
                      className={vibe.Event?.User?.is_following ? 'unfollow-badge' : 'follow-badge'}
                      onClick={() =>
                        vibe.Event?.User?.is_following
                          ? handleUnfollow(vibe.Event?.user_id, vibe.Event?.User?.name)
                          : handleFollow(vibe.Event?.user_id, vibe.Event?.User?.name)
                      }
                    >
                      <FontAwesomeIcon icon={vibe.Event?.User?.is_following ? faTimes : faPlus} />{' '}
                      {vibe.Event?.User?.is_following ? 'Unfollow' : 'Follow'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div id="events" className="container pt-4">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Header />
          <main>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h3 className="inter-700">Vibes</h3>
                </div>
              </div>

              {tags.length > 0 && (
                <>
                  <div className="tag-btn-container">
                    <span className="font-bold block mt-3 mb-3">Select a tag to filter</span>
                    {selectedTag && (
                      <button id="clear_tag" onClick={() => filterVibesByTag(null)}>
                        Clear Filter
                      </button>
                    )}
                  </div>
                  <div className="tag-container">
                    {tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => filterVibesByTag(tag)}
                        className={selectedTag === tag ? 'selected' : ''}
                      >
                        #{tag}
                      </span>
                    ))}
                  </div>
                </>
              )}

              {filteredVibes.length === 0 &&
                thisWeekVibes.length === 0 &&
                nextWeekVibes.length === 0 &&
                futureVibes.length === 0 && <div className="center-flex">You have no vibes.</div>}

              {dayOfWeekMapping[selectedTag] === undefined && filteredVibes.length > 0
                ? filteredVibes.map((vibe) => RenderVibeDetails(vibe))
                : null}

              {dayOfWeekMapping[selectedTag] !== undefined && (
                <>
                  {thisWeekVibes.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">This Week {selectedDay}</h6>
                      <hr className="mb-3" />
                      {thisWeekVibes.map((vibe) => RenderVibeDetails(vibe))}
                    </>
                  )}

                  {nextWeekVibes.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">Next Week {selectedDay}</h6>
                      <hr className="mb-3" />
                      {nextWeekVibes.map((vibe) => RenderVibeDetails(vibe))}
                    </>
                  )}

                  {futureVibes.length > 0 && (
                    <>
                      <hr className="mt-5" />
                      <h6 className="epilogue-400 text-center mt-3 mb-3">Future Vibes {selectedDay}</h6>
                      <hr className="mb-3" />
                      {futureVibes.map((vibe) => RenderVibeDetails(vibe))}
                    </>
                  )}
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

export default Vibes
