import React from 'react'
import { useParams } from 'react-router-dom'
import TicketScan from '../components/ticket/scan/TicketScan'
import { useLocation } from 'react-router-dom'

function TicketScanContainer() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const { rsvpId, influencerId } = useParams()

  return <TicketScan user={user} rsvpId={rsvpId} influencerId={influencerId} />
}

export default TicketScanContainer
