import React, { useState } from 'react'
import FabricEditor from '../../fabric/FabricEditor'
import './FlyerThumbnails.css'

const FlyerThumbnails = (props) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleThumbnailClick = (imageUrl) => {
    setSelectedImage(imageUrl)
  }

  return (
    <div>
      <div className="thumbnail-container" style={{ display: 'flex', gap: '10px' }}>
        {props.images.map((image, index) => (
          <button
            type="button"
            key={index}
            onClick={() => handleThumbnailClick(image)}
            style={{ cursor: 'pointer', padding: 0, border: 'none', background: 'none' }}
          >
            <img src={image} alt={`Thumbnail ${index + 1}`} style={{ width: '120px', height: 'auto' }} />
          </button>
        ))}
      </div>

      {selectedImage && (
        <>
          <div className="mt-3 mb-3">
            <FabricEditor imageURL={selectedImage} handleFileChange={props.handleFileChange} />
          </div>
        </>
      )}
    </div>
  )
}

export default FlyerThumbnails
