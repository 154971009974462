import React, { useState, useEffect } from 'react'

import Tickets from '../components/ticket/list/Tickets'
import { getTickets } from '../services/ticketService'

function TicketsContainer() {
  const userId = JSON.parse(localStorage.getItem('user'))?.userId
  const [activeTab, setActiveTab] = useState('upcoming')
  const [loading, setLoading] = useState(true)
  const [tickets, setTickets] = useState([])
  const [error, setError] = useState(null)

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        setLoading(true)
        const ticketsData = await getTickets(activeTab, userId)
        setTickets(ticketsData.tickets)
        setLoading(false)
      } catch (error) {
        setError('Failed to fetch tickets. Please try again.')
        setLoading(false)
      }
    }

    fetchTickets()
  }, [activeTab, userId])

  return (
    <Tickets activeTab={activeTab} onTabChange={handleTabChange} loading={loading} tickets={tickets} error={error} />
  )
}

export default TicketsContainer
