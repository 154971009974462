import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import './Tickets.css'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

function Tickets({ activeTab, onTabChange, loading, tickets, error }) {
  const navigate = useNavigate()
  return (
    <div id="tickets" className="container" style={{ paddingTop: '40px' }}>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="mb-0 epilogue-400">Tickets</h2>
              <FontAwesomeIcon icon={faBell} className="visually-hidden" />
            </div>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mb-3 d-block text-black mt-3"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            />
          </header>
          <main>
            <div className="container mt-4">
              <div className="custom-tabs">
                <div
                  className={`tab ${activeTab === 'upcoming' ? 'active' : ''} me-4`}
                  onClick={() => onTabChange('upcoming')}
                >
                  Upcoming
                </div>
                <div
                  className={`tab ${activeTab === 'attended' ? 'active' : ''} me-4`}
                  onClick={() => onTabChange('attended')}
                >
                  Attended
                </div>
                <div
                  className={`tab ${activeTab === 'notAttended' ? 'active' : ''}`}
                  onClick={() => onTabChange('notAttended')}
                >
                  Not Attended
                </div>
              </div>

              <div className="tab-content">
                {loading && (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-grow" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}

                {activeTab === 'upcoming' && !loading && (
                  <div>
                    {tickets.length === 0 && <div className="center-flex">You have no upcoming tickets.</div>}
                    {tickets.map((ticket, index) => (
                      <Link to={`${ticket.rsvp_id}`} key={index} className="ticket text-decoration-none">
                        <div className="ticket-image">
                          <img src={ticket.flyer} alt={ticket.name} className="shadow" />
                        </div>
                        <div className="ticket-details text-center w-100">
                          <h3>{ticket.name}</h3>
                          <p>{ticket?.start && formatDate(parseISO(ticket.start))}</p>
                          <Link to={`${ticket.rsvp_id}`} key={index} className="inter-400">
                            View QR Code
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
                {activeTab === 'attended' && !loading && (
                  <div>
                    {tickets.length === 0 && <div className="center-flex">You have no 'attended' tickets.</div>}
                    {tickets.map((ticket, index) => (
                      <Link to={`${ticket.rsvp_id}`} key={index} className="ticket text-decoration-none">
                        <div className="ticket-image">
                          <img src={ticket.flyer} alt={ticket.name} className="shadow" />
                        </div>
                        <div className="ticket-details text-center w-100">
                          <h3>{ticket.name}</h3>
                          <p>{ticket?.start && formatDate(parseISO(ticket.start))}</p>
                          <Link to={`${ticket.rsvp_id}`} key={index} className="inter-400">
                            View QR Code
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
                {activeTab === 'notAttended' && !loading && (
                  <div>
                    {tickets.length === 0 && <div className="center-flex">You have no 'not attended' tickets.</div>}
                    {tickets.map((ticket, index) => (
                      <Link to={`${ticket.rsvp_id}`} key={index} className="ticket text-decoration-none">
                        <div className="ticket-image">
                          <img src={ticket.flyer} alt={ticket.name} className="shadow" />
                        </div>
                        <div className="ticket-details text-center w-100">
                          <h3>{ticket.name}</h3>
                          <p>{ticket?.start && formatDate(parseISO(ticket.start))}</p>
                          <Link to={`${ticket.rsvp_id}`} key={index} className="inter-400">
                            View QR Code
                          </Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

const formatDate = (date) => {
  const day = format(date, 'd')
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  const formattedDate = format(date, 'MMMM d')
  const formattedTime = format(date, 'h:mma').toLowerCase()
  return `${formattedDate}${daySuffix(day)}. ${formattedTime}`
}

export default Tickets
