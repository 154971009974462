import React from 'react'

const Toasts = ({ show, message, onClose }) => {
  return (
    <div className="toast-container position-fixed top-0 end-0 p-5">
      <div className={`toast ${show ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <strong className="me-auto">Notification</strong>
          <button type="button" className="btn-close" onClick={onClose}></button>
        </div>
        <div className="toast-body" dangerouslySetInnerHTML={{ __html: message }}></div>
      </div>
    </div>
  )
}

export default Toasts
