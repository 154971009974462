import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faCircleQuestion, faUser } from '@fortawesome/free-regular-svg-icons'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import './Header.css'

export function Header() {
  const navigate = useNavigate()

  return (
    <header>
      <div className="row mb-5">
        <div className="d-flex justify-content-around align-items-center">
          <img src="/assets/images/logo.svg" alt="GreatNight Logo" id="logo" onClick={() => navigate('/promoter/')} />
          <FontAwesomeIcon icon={faBell} className="visually-hidden" />
          <FontAwesomeIcon
            icon={faCircleQuestion}
            id="icons"
            className="icon-hover"
            onClick={() => navigate('/promoter/help')}
          />
          <FontAwesomeIcon icon={faCamera} className="icon-hover" onClick={() => navigate('/promoter/my-event')} />
          <FontAwesomeIcon icon={faUser} className="icon-hover" onClick={() => navigate('/promoter/profile')} />
        </div>
      </div>
    </header>
  )
}
