import React, { useState } from 'react'
import './ShareEventModal.css'
import Toasts from '../../common/toasts/Toasts'
import { shareEventByEmail } from '../../../services/eventService'

function ShareEmailModal({ showModal, setShowModal, eventDetails }) {
  const [loading, setLoading] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({ email: '' })
  const [errors, setErrors] = useState({ email: '' })

  const handleCloseModal = () => {
    setFormData({ email: '' })
    setShowModal(false)
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = async () => {
    const { email } = formData
    const eventSlug = eventDetails?.slug

    if (!email) {
      setErrors((prevState) => ({ ...prevState, email: 'Email is required' }))
      return
    }

    if (!validateEmail(email)) {
      setErrors((prevState) => ({ ...prevState, email: 'Invalid email format' }))
      return
    }

    try {
      setLoading(true)

      try {
        await shareEventByEmail(email, eventSlug)
        setMessage('Event link shared via email successfully')
        setShowToast(true)
      } catch (error) {
        setMessage('Failed to share event link via email')
        setShowToast(true)
        console.error('Error sharing event via email:', error)
      } finally {
        setLoading(false)
      }

      setLoading(false)
      setMessage('Email sent successfully.')
      setShowToast(true)

      setFormData({ email: '' })
      handleCloseModal()
    } catch (error) {
      console.error('Error sending email:', error)
      setMessage(`Error sending email: ${error.message}`)
      setShowToast(true)
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))

    if (name === 'email' && errors.email) {
      setErrors((prevState) => ({ ...prevState, email: '' }))
    }
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target
    if (name === 'email') {
      if (!value) {
        setErrors((prevState) => ({ ...prevState, email: 'Email is required' }))
      } else if (!validateEmail(value)) {
        setErrors((prevState) => ({ ...prevState, email: 'Invalid email format' }))
      }
    }
  }

  return (
    <div
      className={`modal fade ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      id="share-email-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="shareEmailModalLabel"
      aria-hidden={!showModal}
      data-bs-backdrop="static"
    >
      <Toasts show={showToast} message={message} onClose={() => setShowToast(false)} />
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="shareEmailModalLabel">
              Share Event
            </h5>
            <button
              type="button"
              className="close border-0 bg-transparent btn-outline-primary"
              onClick={handleCloseModal}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {loading && (
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div className="spinner-grow" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Recipient Email"
              />
              <div className="form-text text-danger">{errors?.email}</div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="btn-send-email"
              className="btn mb-2"
              onClick={handleSubmit}
              disabled={!formData.email || loading}
            >
              Share
            </button>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show z-n1"></div>}
    </div>
  )
}

export default ShareEmailModal
